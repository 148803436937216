<template>
  <input
    v-model="picked"
    type="radio"
  >
</template>

<script>
export default {
    name: 'MainRadio'
}
</script>

<style scoped>
input {
width: 20px;
height: 20px;
border: 2px solid #d9d9d9;
border-radius: 50%;
appearance: none;
transition: 0.2s all linear;
outline: none;
}
input:hover {
    background: #d9d9d9;
}
input:checked {
    border: 6px solid #264AC7;
    background: white;
}
input:disabled {
    background: #959595;
}
</style>

