<template>
  <offer-vue
    :title="'ПОРУЧНИ'"
    :imgsrc="'offer_imgs/rail/rail.webp'"
  >
    Предлагаем установку поручней, ограждений из нержавеющей стали, металлических окрашенных или ПВХ поручней в подъезде или на крыльце Вашего дома  
  </offer-vue>
  <cards-vue
    :type="'products'"
    :render-border-on-cards="false"
    :cards="cardsJson"
    @openModal="(product) => openModal(product)"
  />
  <transition name="modal">
    <product-modal
      v-if="isModalVisible"
      :content="modalContent"
      :page-section="'Поручни'"
      unit-price="руб./метр"
      @openForm="openForm()"
      @close="isModalVisible = false"
    />
  </transition>
  <transition name="modal">
    <main-modal
      v-if="isFormVisible"
      @close="isFormVisible = false"
    >
      <form-vue />
    </main-modal>
  </transition>
</template>

<script>
import cardsJson from './rail-cards.json'
export default {
name: 'RailPage',
data() {
    return {
       isModalVisible: false,
       modalContent: new Object,
       isFormVisible: false,
       cardsJson: cardsJson
    }
},
methods: {
    openModal(product) {
        this.isModalVisible = !this.isModalVisible;
        this.modalContent = product;
    },
    openForm() {
        this.isFormVisible = true;
        this.isModalVisible = false;
    }
}
}
</script>

<style>

</style>