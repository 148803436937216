<template>
  <div class="calc">
    <template v-if="calcType === 'calcPVL'">
      <main-input
        v-model.number="w"
        :type="'number'"
        :placeholder="'Длина в см'"
        :min="20"
        :max="600"
        :step="1"
        @input="checkValid(v$.w); $emit('newPrice', calcPVL), $emit('getWidth', w)"
      />
      <line-invalid v-if="v$.w.$error">
        Некорректное значение
      </line-invalid>
      <main-input
        v-model.number="h"
        :type="'number'"
        :placeholder="'Высота в см'"
        :min="20"
        :max="600"
        :step="1"
        @input="checkValid(v$.h); $emit('newPrice', calcPVL), $emit('getHeight', h)"
      />
      <line-invalid v-if="v$.h.$error ">
        Некорректное значение
      </line-invalid>
    </template>

    <template v-if="calcType === 'calcStrip'">
      <main-input
        v-model.number="w"
        :type="'number'"
        :placeholder="'Длина в см'"
        :min="20"
        :max="600"
        :step="1"
        @input="checkValid(v$.w); $emit('newPrice', calcStrip), $emit('getWidth', w)"
      />
      <line-invalid v-if="v$.w.$error ">
        Некорректное значение
      </line-invalid>
      <main-input
        v-model.number="h"
        :type="'number'"
        :placeholder="'Высота в см'"
        :min="20"
        :max="600"
        :step="1"
        @input="checkValid(v$.h); $emit('newPrice', calcStrip), $emit('getHeight', h)"
      />
      <line-invalid v-if="v$.h.$error">
        Некорректное значение
      </line-invalid>
    </template>

    <template v-if="calcType === 'calcSteel'">
      <main-input
        v-model.number="w"
        :type="'number'"
        :placeholder="'Длина в см'"
        :min="20"
        :max="600"
        :step="1"
        @input="checkValid(v$.w); $emit('newPrice', calcSteel), $emit('getHeight', h)"
      />
      <line-invalid v-if="v$.w.$error">
        Некорректное значение
      </line-invalid>
      <main-input
        v-model.number="h"
        :type="'number'"
        :placeholder="'Высота в см'"
        :min="20"
        :max="600"
        :step="1"
        @input="checkValid(v$.h); $emit('newPrice', calcSteel), $emit('getWidth', w)"
      />
      <line-invalid v-if="v$.h.$error">
        Некорректное значение
      </line-invalid>
    </template>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
const within = (value) => (value <= 600 && value >= 20) || value === '' || value === 0;
export default {
name: 'MainCalc',
props: {
    perimeterPrice: {
        type: Number
    },
    squarePrice: {
        type: Number
    },
    calcType: {
        type: String
    }
},
setup() {
    return { v$: useVuelidate() }
},
data() {
    return {
        w: '',
        h: '',
        dye: 300,
        work: 1500,
        transport: 500
    }
},
validations: {
    w: {within},
    h: {within}
},
computed: {
    square() {
        return this.wInMeters * this.hInMeters
    },
    wInMeters() {
        return this.w / 100
    },
    hInMeters() {
        return this.h / 100
    },
    perimeter() {
        return (this.wInMeters + this.hInMeters) * 2
    },
    calcPVL() {
        if (this.wInMeters >= 0.2 && this.hInMeters >= 0.2) {
            return Math.ceil((this.perimeter * this.perimeterPrice + this.wInMeters * this.hInMeters  * this.squarePrice + this.dye + this.work + this.transport) * 2.5)
        } else {
            return 0
        }
    },
    calcStrip() {
        if (this.wInMeters >= 0.2 && this.hInMeters >= 0.2) {
            return Math.ceil((this.perimeter * this.perimeterPrice + this.wInMeters * this.hInMeters * 25  * this.squarePrice + this.dye + this.work + this.transport) * 2.5)
        } else {
            return 0
        }
    },
    calcSteel() {
        if (this.wInMeters >= 0.2 && this.hInMeters >= 0.2) {
            return Math.ceil((this.perimeter * this.perimeterPrice + this.wInMeters * this.hInMeters * 20 * this.squarePrice + this.dye + this.work + this.transport) * 2.5)
        } else {
            return 0
        }
    }
},
methods: {
    checkValid(valid) {
        valid.$touch();
        this.$emit('invalid', (this.v$.w.$invalid || this.v$.h.$invalid) ? true : false);
    }
}
}
</script>

<style scoped>
.calc {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>