<template>
  <div class="close" />
</template>

<script>
export default {
    name: 'CloseButton'
}
</script>

<style scoped>
.close{
    width: 55px;
    height: 55px;
    cursor: pointer;
}
.close:before,
.close:after {
    position: absolute;
    content: "";
    top: 10%;
    right: 10%;
    width: 26px;
    height: 4px;
    background: #d4d4d4;
    transition: 300ms ease;
}
.close:hover::before, .close:hover::after {
    background: #fff;
    transition: 300ms ease;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}
</style>