<template>
  <div
    class="container"
    @click="$emit('openModal')"
  >
    <div
      class="img-wrapper"
      :class="{'img-border img-br': isBorder}"
    >
      <div
        class="img"
        :class="{'center-img': isNativeImg}"
      >
        <img 
          v-if="content.imgsrc !== undefined" 
          :loading="loading"
          decoding="async" 
          style="background-size: cover; background-image: none"
          :alt="content.title"
          :src="$store.state.img.setAssetsPath(content.imgsrc)"
          :class="{'img-br': !isBorder, 'img-width-100': !isNativeImg}"
        >
      </div>
    </div>
    <div class="text">
      <span class="title">{{ content.title }}</span>
      <p
        v-if="content.price !== undefined"
        class="price"
      >
        {{ content.price + ' руб.' }}
      </p>
    </div>
    <div class="btn">
      <card-button>Посмотреть</card-button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ProductCard',
    props: {
        content: {
            type: Object
        },
        isBorder: {
            type: Boolean,
            default: true
        },
        isNativeImg: {
            type: Boolean,
            default: false
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
    .container {
        width: 80vw;
    }
    .img, .btn {
        padding-bottom: 2.8vw;
    }
    img {
        max-height: 60.4vw;
    }
    .title {
        padding-bottom: 7.4vw;
    }
    .text {
        padding: 4vw 0px 3.8vw 0px;
    }
}
@media screen and (max-width: 900px) and (min-width: 601px) {
    .container {
        width: 40vw;
    }
    .img, .btn {
        padding-bottom: 1.4vw;
    }
    img {
        max-height: 30.2vw;
    }
    .title {
        padding-bottom: 2.7vw;
    }
    .text {
        padding: 2vw 0px 1.9vw 0px;
    }
}
@media screen and (max-width: 1300px) and (min-width: 901px) {
    .container {
        width: 25vw;
    }
    .img, .btn {
        padding-bottom: 0.9vw;
    }
    img {
        max-height: 18.9vw;
    }
    .title {
        padding-bottom: 1.5vw;
        font-size: 1.7vw;
        line-height: 2vw;
    }
    .text {
        padding: 1.3vw 0px 1.2vw 0px;
    }
}
@media screen and (min-width: 1300px) {
    .container {
        width: 17.2vw;
    }
    .img, .btn {
        padding-bottom: 0.6vw;
    }
    img {
        max-height: 13vw;
    }
    .title {
        padding-bottom: 1.5vw;
    }
    .text {
        padding: 0.9vw 0px 0.8vw 0px;
    }
}
.title {
    font-size: 24px;
    line-height: 32px;
}
.img-width-100 {
    width: 100%;
}
.center-img {
    text-align: center;
}
.container {
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.25);
    align-items: center;
    padding-top: 1px;
}
.img, .btn{
    display: block;
    margin: 0 auto;
}
.btn {
    width: 80%;
    max-width: fit-content;
}
.img-wrapper {
    margin: 12px;
}
.img-border {
    border: 1px solid #264AC7;
    padding: 15px;
}
.img-br {
    border-radius: 10px;
}
.title {
    font-weight: 500;
    color: #264AC7;
}
.price {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
}
.text {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}
</style>