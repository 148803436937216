<template>
  <div class="container">
    <h2 class="text">
      Извините! Страницы не существует или она находится в разработке...
    </h2>
  </div>
</template>

<script>
export default {
name: 'EmptyPage'
}
</script>

<style scoped>
.container {
position: relative;
height: 75vh;
width: 70%;
margin-left: auto;
margin-right: auto;
}
.text {
position: absolute;
top: 40%;
width: 100%;
text-align: center;
color: #264ac7;

}
@media screen {
    
}
</style>