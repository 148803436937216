<template>
  <div class="cards-container">
    <div
      v-if="type === 'services'"
      class="wrapper"
    >
      <service-card
        v-for="(card, index) in cards"
        :key="index"
        class="card"
        :card="card"
        :loading="index === 0 ? 'eager' : 'lazy'"
        @openModal="$emit('openModal')"
      />
    </div>
    <div
      v-if="type === 'products'"
      class="wrapper"
    >
      <product-card
        v-for="(card, index) in cards"
        :key="index"
        class="card"
        :loading="index === 0 ? 'eager' : 'lazy'"
        :is-native-img="isNativeImgsOnCards"
        :content="card"
        :is-border="renderBorderOnCards"
        @openModal="$emit('openModal', card)"
      />
    </div>
  </div>
</template>

<script>
export default {
name: 'CardsVue',
props: {
    cards: {
        type: Array
    },
    type: {
        type: String
    },
    isNativeImgsOnCards: {
        type: Boolean,
        default: false
    },
    renderBorderOnCards: {
        type: Boolean,
        default: true
    }
}
}
</script>

<style scoped>
@media screen and (min-width: 601px) {
    .card {
        margin: 20px;
    }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
    .wrapper {
        grid-template-columns: auto auto;
    }
}
@media screen and (min-width: 901px) {
    .wrapper {
       grid-template-columns: auto auto auto;
    }
}
@media screen and (max-width: 600px) {
    .card {
        margin: 3vw;
    }
    .wrapper {
        grid-template-columns: auto;
    }
}
.wrapper {
    display: grid;
}
button {
    border: 0px;
    background: #fff;
}
.cards-container {
    display: flex;
    justify-content: center;
}
.card {
    display: block;
}
</style>