<template>
  <div class="p-content">
    <slot />
  </div>
</template>

<script>
export default {
name: 'ParagraphVue'
}
</script>

<style scoped>
.p-content {
    background: rgb(38, 74, 199, 0.1);
    border-radius: 20px;
    padding: 20px;
    margin: 20px auto;
}

@media screen and (min-width: 900px) {
    .p-content{
        width: 55vw;
    }
}
@media screen and (max-width: 900px) {
    .p-content {
        width: 75vw;
    }
}
</style>