<template>
  <offer-vue 
    :title="'Навесы над вентшахтами'" 
    :imgsrc="'offer_imgs/peak_shafts/peak_shafts.webp'"
    @windowWidth="(windowWidth) => {isMobile = windowWidth < 900 ? true : false}"
  >
    Для защиты вентиляционных шахт и помещений чердаков
    и технических этажей от осадков 
    предлагаем установку прочных конструкций, устойчивых к порывам ветра<br><br>
    <main-button
      v-if="!isMobile"
      @click="isFormVisible = true"
      @close="isFormVisible = false"
    >
      ЗАКАЗАТЬ
    </main-button>
  </offer-vue>
  <div class="container">
    <paragraph-vue>
      <b>Конструкция и материалы:</b><br>
      <ul>
        <li>Навес устанавливается над существующей кирпичной вентшахтой.</li>
        <li>Представляет собой каркас в форме четырехсторонней пирамиды из стального уголка 35х35 мм и профильной трубы 40х40 и 25х25 мм, смонтированный на верхнем срезе шахты.</li>
        <li>Сверху каркас обшивается профлистом С8.</li>
        <li>Для сохранения функции вентиляции чердачного помещения по периметру навеса под свесами боковых сторон остается пространство шириной 100 мм по всему периметру навеса.</li>
        <li>Каркас окрашивается эмалью по металлу 3-в-1. Цвет профлиста – по согласованию с заказчиком.</li>
      </ul>
    </paragraph-vue>
    <main-button
      v-if="isMobile" 
      class="btn-bottom"
      @click="isFormVisible = true" 
      @close="isFormVisible = false"
    >
      ЗАКАЗАТЬ
    </main-button>
  </div>
  <transition name="modal">
    <main-modal
      v-if="isFormVisible"
      @close="isFormVisible = false"
    >
      <form-vue />
    </main-modal>
  </transition>
</template>

<script>
export default {
name: 'PeakShaftsPage',
data() {
    return {
        isFormVisible: false,
        isMobile: false
    }
}
}
</script>

<style scoped>
.btn-bottom {
    display: block;
    margin: 20px auto;
}
</style>