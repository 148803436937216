<template>
  <header class="header">
    <div class="vk">
      <a href="https://vk.com/club225760557"><img
        alt="vk"
        :src="$store.state.img.setAssetsPath($store.state.img.imgPath('icons/vk.svg'))"
        width="22"
        height="22"
      ></a>
    </div>
    <div class="header-mail">
      <img
        alt="e-mail"
        :src="$store.state.img.setAssetsPath($store.state.img.imgPath('icons/mail.svg'))"
        width="22"
        height="22"
      >
      <p>spb.astrei@yandex.ru</p>
    </div>
    <div class="header-phone">
      <img
        alt="Телефон"
        :src="$store.state.img.setAssetsPath($store.state.img.imgPath('icons/phone.svg'))"
        width="22"
        height="22"
      >
      <p>(812) 982 32 12</p>
    </div>
    <div class="wa-phone">
      <img
        alt="whatsapp"
        :src="$store.state.img.setAssetsPath($store.state.img.imgPath('icons/whatsapp.svg'))"
        width="22"
        height="22"
      >
      <p>+7 905 222-32-12</p>
    </div>
    <div
      class="action-item call-title"
      @click="$emit('openForm')"
    >
      <p>ЗАКАЗАТЬ ЗВОНОК</p>
    </div>
  </header>
</template>
    
<script>
    export default {
      name: 'HeaderVue',
    }
</script>
    
<style scoped>
  .header {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    background: rgb(38, 74, 199);
  }
  .header > div {
    display: flex;
    font-size: large;
    align-items: center;
    margin: 0px 20px;
    gap: 8px;
  }
  p {
    color: #FFFFFF;
  }
  .action-item {
    cursor: pointer;
  }
  .action-item:hover {
    text-decoration: underline #FFFFFF;

  }
  a {
    display: contents;
  }
@media screen and (max-width: 900px) {
  .header {
    display: none;
  }
}
</style>