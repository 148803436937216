<template>
  <offer-vue
    :title="'Ремонт козырьков'"
    :imgsrc="'peak_repare_imgs/1/1.webp'"
  >
    Если козырек в вашем доме поврежден и требует ремонта, выполним работы:<br>
    <ul class="enum">
      <li>замена покрытия козырька</li>
      <li>ремонт и замена каркаса, декоративных элементов</li>
      <li>восстановление герметичности, ремонт стыков</li>
      <li>окраска конструкции</li>
      <li>работаем с любыми типами и конструкциями козырьков</li>
    </ul>
    <main-button
      @click="isFormVisible = true"
      @close="isFormVisible = false"
    >
      ЗАКАЗАТЬ
    </main-button>
  </offer-vue>
  <row-title><h2>ДО РЕМОНТА / ПОСЛЕ РЕМОНТА</h2></row-title>
  <div class="compar-container">
    <div class="compar-img">
      <img
        width="800"
        :src="$store.state.img.setAssetsPath(adaptiveImgPath(1280, 'peak_repare_imgs/1/1.webp'))"
        alt="Ремонт козырьков"
      >
    </div>
    <div class="compar-img">
      <img
        width="800"
        :src="$store.state.img.setAssetsPath(adaptiveImgPath(1280, 'peak_repare_imgs/2/2.webp'))"
        alt="Ремонт козырьков"
      >
    </div>
    <div class="compar-img">
      <img
        width="800"
        :src="$store.state.img.setAssetsPath(adaptiveImgPath(1280, 'peak_repare_imgs/3/3.webp'))"
        alt="Ремонт козырьков"
      >
    </div>
    <div class="compar-img">
      <img
        width="800"
        :src="$store.state.img.setAssetsPath(adaptiveImgPath(1280, 'peak_repare_imgs/4/4.webp'))"
        alt="Ремонт козырьков"
      >
    </div>
    <div class="compar-img">
      <img
        width="800"
        :src="$store.state.img.setAssetsPath(adaptiveImgPath(1280, 'peak_repare_imgs/5/5.webp'))"
        alt="Ремонт козырьков"
      >
    </div>
  </div>
  <div class="description">
    <paragraph-vue>
      Цена рассчитывается индивидуально с учётом:<br><br>
      •	Материала покрытия конструкции: стекло, металл, поликарбонат, профлист, металлочерепица;<br>
      •	Характера повреждений: деформация каркаса, несущих конструкций; повреждение покрытия, декоративных элементов; разгерметизация примыканий и стыков.<br>
      •	Особенности выполнения – для работы с балконами и верхними этажами привлекаем промышленных альпинистов.<br>
      •	Размеров конструкции<br>
      Замер и расчет стоимости работ – бесплатно.
    </paragraph-vue>
  </div>
  <main-button
    class="last-btn"
    @click="isFormVisible = true"
  >
    ЗАКАЗАТЬ
  </main-button>
  <transition name="modal">
    <main-modal
      v-if="isFormVisible"
      @close="isFormVisible = false"
    >
      <form-vue />
    </main-modal>
  </transition>
</template>

<script>
export default {
name: 'PeakRepairPage',
data() {
    return {
        isFormVisible: false
    }
},
methods: {
    adaptiveImgPath(windowWidth, imgsrc) {
        return this.$store.state.img.adaptiveImgPath(windowWidth, imgsrc)
    }
}
}
</script>

<style scoped>
ul {
    padding-left: .8vw;
    padding-bottom: 1.2vw;
}
li {
    margin-top: .7vw;
}
.compar-container {
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.compar-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
}
.compar-img, .compar-img-last {
    display: block;
    border-radius: 7px;
}
.compar-img img, .compar-img-last img {
    object-fit: cover;
    border-radius: 3%;
}
.last-btn {
    display: block;
    margin: 20px auto;
}
@media screen and (min-width: 900px) {
    .compar-img img{
        width: 65vw;
    }
}
@media screen and (max-width: 900px) {
    .compar-img img {
        width: 75vw;
    }
}
</style>