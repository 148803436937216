<template>
  <main-modal>
    <div class="item-wrapper">
      <div class="img-wrapper">
        <img
          :src="$store.state.img.setAssetsPath(content.imgsrc)"
          alt=""
          class="item-img"
        >
      </div>
      <div class="item-separator" />
      <div class="item-container">
        <div class="item-title">
          {{ content.title }}
        </div>
        <div class="item-description">
          <span
            v-for="line in textArray"
            :key="line.number"
          >
            <span v-if="line.includes('<b>')"><b>{{ line.substring(0, line.length - 3) }}</b></span>
            <span v-if="!line.includes('<b>')">{{ line }}</span><br>
          </span>
          <button
            v-show="description !== undefined && text.length !== description.length"
            class="text-btn"
            @click="text = description"
          >
            Показать
          </button>
          <button
            v-show="description !== undefined && text.length > 203"
            class="text-btn"
            @click="text = hideText(description)"
          >
            Скрыть
          </button>
        </div>
        <div
          v-for="selector in content.selectors"
          :key="selector.number"
          class="selector-container"
        >
          <div class="selector-title">
            {{ selector.title }}
          </div>
          <div
            v-for="prop in selector.props"
            :key="prop.number"
            class="selector-prop"
          >
            <main-radio
              :id="prop.value"
              v-model="picked[selector.name]"
              :disabled="disableProp(prop.disabled)"
              :name="selector.name"
              :value="prop.value"
              checked
            />
            <label :for="prop.value">{{ prop.value }}</label>
          </div>
        </div>
        <div
          v-if="content.isCalc"
          class="calc-container"
        >
          <main-calc
            :calc-type="content.calcType"
            :perimeter-price="content.perimeterPrice" 
            :square-price="content.squarePrice" 
            @getWidth="(w) => {if (!invalid) {productSize.w = w}}"
            @getHeight="(h) => {if (!invalid) {productSize.h = h}}"
            @newPrice="(price) => {if (!invalid) {actualPrice = price} else {actualPrice = 0}}" 
            @invalid="(p) => {invalid = p}"
          />
        </div>
        <div
          v-if="content.price !== undefined || (content.perimeterPrice !== undefined && content.squarePrice !== undefined)"
          class="item-price"
        >
          <b>Цена: {{ calcPrice(actualPrice) + ` ${unitPrice}` }}</b>
        </div>
        <main-button @click="if (!invalid) {$emit('openForm'); createPostBody()}">
          ЗАКАЗАТЬ
        </main-button>
      </div>
    </div>
  </main-modal>
</template>

<script>
export default {
name: 'ProductModal',
props: {
    pageSection: {
        type: String, 
        required: true
    },
    content: {
        type: Object
    },
    descriptionCommon: {
        type: String
    },
    unitPrice: {
        type: String,
        default: 'руб.'
    }
},
data() {
    return {
        invalid: false,
        picked: new Object,
        text: '',
        actualPrice: -1,
        description: '',
        productSize: new Object,
        postBody: new Object,
        assetsPath: process.env.VUE_APP_ASSETS_PATH
    };
},
computed: {
    textArray: {
        get() {
            return this.text.split('\n')
        }
    }
},
mounted() {
    if (this.content.description === undefined && this.descriptionCommon !== undefined) {
        this.description = this.descriptionCommon;
    } else {
        this.description = this.content.description;
    }
    if (this.description !== undefined) {
        this.text = this.hideText(this.description);
    }
    if (this.content.selectors !== undefined) {
        for (let sel of this.content.selectors) {
            this.picked[sel.name] = sel.props.at(-1).value;
        }
    }
},
updated() {
    this.calcPrice(this.actualPrice)
},
methods: {
    hideText(text) {
        return text.length > 200 ? text.substring(0, 200) + '...' : text
    },
    calcPrice(price) {
        if (this.actualPrice === -1) {
            if (this.content.isCalc === true) {
                return 0
            }
            price = this.content.price
        }
        if (this.content.selectors !== undefined) {
            for (let sel of this.content.selectors) {
                let addPrice = sel.props.filter(prop => (this.picked[sel.name] === prop.value))[0]?.addPrice
                addPrice !== undefined ? price += sel.props.filter(prop => (this.picked[sel.name] === prop.value))[0]?.addPrice : price
            }
        }
        return price
    },
    disableProp(disabledProps) {
        if (disabledProps !== undefined) {
            for (let prop of disabledProps) {
                if (this.picked[Object.keys(prop)[0]] === Object.values(prop)[0]) {
                    return true;
                }
            }
        }
    },
    createPostBody() {
        this.postBody["Раздел"] = this.pageSection;
        this.postBody["Тип"] = this.content.title;
        if (this.content.selectors !== undefined) {
            this.content.selectors.map((e) => this.postBody[e.title] = this.picked[e.name]);
        }
        if (this.content.isCalc !== undefined) {
            this.postBody["Длина"] = this.productSize.w;
            this.postBody["Высота"] = this.productSize.h;
            this.postBody["Цена"] = this.actualPrice;
        } else {
            this.postBody["Цена"] = this.content.price;
        }
        // JSON.stringify()
        this.$store.commit('CHANGE_POSTBODY_PRODUCT', this.postBody);
        
    }
}
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
    .item-wrapper {
        padding: 4.3vw;
    }
    .item-container {
        max-width: 78vw;
    }
}
@media screen and (min-width: 601px) and (max-width: 1000px) {
    .item-wrapper {
        padding: 30px;
    }
    .item-container {
        max-width: 60vw;
    }
}
@media screen and (max-width: 1000px) {
    .item-img {
        max-width: 60vw;
        max-height: 40vh;
    }
    .item-wrapper {
        flex-direction: column;
        gap: 3.3vw;
    }
}
@media screen and (min-width: 1001px) {
    .item-wrapper {
        gap: 30px;
        align-items: center;
    }
    .item-img {
        max-width: 100%;
        max-height: 40vh;
    }
    .item-container {
        max-width: 400px;
    }
}
.item-wrapper {
    display: flex;
}
.img-wrapper {
    text-align: center;
}
.item-separator {
    border-left: 1px solid grey;
}
.item-container > div {
    padding-bottom: 10px;
}
.item-title {
    font-size: 24px;
}
.item-description {
    font-size: 16px;
    color: black;
}
.selector-prop {
    display: flex;
    gap: 6px;
    align-items: center;
}
.text-btn {
    cursor: pointer;
    color: #264ac7;
    background: #fff;
}
.text-btn:hover {
    color: #0A2278;
}
input[type=radio]:disabled+label {
    color: #b2b2b2
}
</style>