<template>
  <div class="topbar">
    <transition name="mask">
      <div
        v-if="isSidebarVisible" 
        class="sidebar-mask"
        @click="moveSidebar()"
      />
    </transition>
    <!-- <router-link :to="'/'">
        <img class="logo" alt="Астрей. Логотип" :height="60" :src="$store.state.img.setAssetsPath(require('@/assets/logo2.svg'))">
    </router-link> -->
    <nav class="topbar-container">
      <div
        class="sidebar-btn"
        @click="moveSidebar()"
      >
        <side-bar-button />
      </div>
      <div
        v-if="!isSidebarVisible"
        class="links-wrapper-desktop"
      >
        <template
          v-for="page in pages"
          :key="page"
        >
          <router-link
            v-if="!isDrop(page)"
            :to="getLink(page)"
            class="btn" 
            @click.stop=""
          >
            <div
              class="title"
              :class="{'red': page.isRed}"
            >
              {{ page.title }}
            </div>
          </router-link>
          <button
            v-else
            class="btn dropbtn"
          >
            <div
              class="title"
              :class="{'red': page.isRed}"
            >
              {{ page.title }}
            </div>
            <div
              v-if="page.dropbox !== undefined"
              class="dropbox"
            >
              <template
                v-for="item in page.dropbox" 
                :key="item"
              >
                <router-link
                  v-if="getLink(item)"
                  class="dropbox-item"
                  :to="getLink(item)"
                >
                  {{ item.title }}
                </router-link>
                <button
                  v-else
                  class="dropbox-item"
                  @click="openModal(item)"
                >
                  {{ item.title }}
                </button>
              </template>
            </div>
          </button>
        </template>
      </div>
      <transition name="sidebar">
        <div
          v-if="isSidebarVisible"
          class="links-container-mobile"
        >
          <div class="links-wrapper-mobile">
            <template
              v-for="page in pages"
              :key="page"
            >
              <router-link
                v-if="!isDrop(page)" 
                :to="getLink(page)"
                class="btn"
                @click="closeSidebar(page)"
              >
                <div
                  class="title"
                  :class="{'red': page.isRed}"
                >
                  {{ page.title }}
                </div>
                <div
                  v-if="page.dropbox !== undefined"
                  class="dropbox-mobile"
                >
                  <router-link
                    v-for="item in page.dropbox" 
                    :key="item"
                    :to="getLink(item)" 
                    @click="openModal(item), closeSidebar(item)"
                  >
                    <div class="title">
                      {{ item.title }}
                    </div>
                  </router-link>
                </div>
              </router-link>
              <button
                v-else
                class="btn dropbtn"
              >
                <div
                  class="title"
                  :class="{'red': page.isRed}"
                >
                  {{ page.title }}
                </div>
                <div
                  v-if="page.dropbox !== undefined"
                  class="dropbox-mobile"
                >
                  <template
                    v-for="item in page.dropbox" 
                    :key="item"
                  >
                    <router-link
                      v-if="getLink(item)"
                      class="btn" 
                      :to="getLink(item)"
                      @click="closeSidebar(item)"
                    >
                      <div class="title">
                        {{ item.title }}
                      </div>
                    </router-link>
                    <button
                      v-else
                      class="btn"
                      @click="openModal(item), closeSidebar(item)"
                    >
                      <div class="title">
                        {{ item.title }}
                      </div>
                    </button>
                  </template>
                </div>
              </button>
            </template>
          </div>
        </div>
      </transition>
    </nav>
    <transition name="modal">
      <home-modal
        v-if="isModalVisible"
        @close="closeModal"
      />
    </transition>
    <transition name="modal">
      <main-modal
        v-if="isFormVisible"
        @close="isFormVisible = false"
      >
        <form-vue />
      </main-modal>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'TopbarVue',
    props: {
        pages: {
            type: Array
        },
    },
    data() {
        return {
            isFormVisible: false,
            isHidden: false,
            isSidebarVisible: false
        }
    },
    computed: {
        isModalVisible() {
            return this.modalItemsCount !== 0;
        },
        ...mapGetters(["modalItemsCount"])
    },
    methods: {
        isDrop(page) {
            if (page.dropbox !== undefined) {
                return true;
            }
            return false;
        },
        getLink(page) {
            if (page.sections === undefined && page.dropbox === undefined) {
                return page.link !== undefined ? page.link : '/empty';
            } else {
                return ''
            }
        },
        openModal(item) {
            if (item.sections !== undefined) {
                this.$store.commit('CHANGE_MODAL_ITEMS', item.sections);
            }
        },
        closeModal() {
            this.$store.commit('CHANGE_MODAL_ITEMS', []);
        },
        moveSidebar() {
            if (!this.isSidebarVisible) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'scroll';    
            }
            this.isSidebarVisible = !this.isSidebarVisible
        },
        closeSidebar(page) {
            if (page.dropbox === undefined) {
                this.moveSidebar()
            }
        } 
    },
}
</script>

<style scoped>
button {
    background: inherit;
    width: 100%;
    cursor: pointer;
}
.logo {
    position: absolute;
    left: 5vw;
    z-index: 0;
    background-color: rgb(38, 74, 199);
}
.dropbox-mobile {
    position: absolute;
    display: none;
    top: 0px;
    flex-direction: column;
    right: 100%;
    background-color: var(--topbar-color);
    overflow-y: scroll;
    height: 70vh;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}
.red{
    color: #990304;
}
.sidebar-mask {
        position: fixed;
        display: block;
        z-index: -2;
        overflow-y: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
    }
.topbar {
    display: block;
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--topbar-color);
    box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
}
.topbar-container {
    display: flex;
    flex-direction: row;
    height: inherit;
    float: right;
    margin-right: 5%;
}
.links-wrapper-mobile, .links-wrapper-desktop {
    display: flex;
}
.btn {
    display: flex;
    height: inherit;
    width: max-content;
    align-items: center;
    cursor: pointer;
    transition: 0.1s ease;
}
.title {
    padding: 0px 1.8vw;
}
.dropbox {
    position: absolute;
    display: none;
    top: 100%;
    left: auto;
    background-color: var(--topbar-color);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.8);
    z-index: 0;
}
.dropbox-item {
    display: block;
    text-align: left;
    padding: 12px 16px;
    text-decoration: none;
    transition: 0.1s ease;
}
.sidebar-btn {
    display: none;
    height: fit-content;
}
.mask-enter-active, .mask-leave-active {
    transition: all 0.4s ease;
} 
.mask-enter-from,
.mask-leave-to {
    opacity: 0;

}
@media screen and (max-width: 900px) {
    .header {
        display: none;
    }
    .links-wrapper-desktop {
        display: none;
    }
    .links-container-mobile {
        height: 100vh;
        background-color: var(--topbar-color);
        box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
    }
    .links-wrapper-mobile {
        display: block;
        position: relative;
    }
    .sidebar-btn {
        display: block;
    }
    .topbar-container {
        position: absolute;
        right: 0;
        margin-right: 0;
    } 
    .title {
        padding: 30px 2.2vw;
    }
    .dropbox {
        top: auto;
        right: 100%;
    }
    .btn {
        width: 100%;
        padding: 0px 5vw 0px 1.5vw;
    }
    .dropbox-item:active {
        background: #fff;
        transition: 0.1s ease;
    }
    .btn:active {
        background: white;
        color: rgb(38, 74, 199);
        transition: 0.1s ease;
    }
}
@media screen and (max-width: 600px) {
    .title {
        font-size: 16px;
    }
    .logo {
        left: 0;
        height: 40px;
        top: 15%;
    }
}
@media screen and (min-width: 900px) {
    .dropbox-item:hover {
        background: #fff;
        transition: 0.1s ease;
    }
    .dropbtn:hover .dropbox {
        display: block;
    }
    .btn:hover {
        background: white;
        color: rgb(38, 74, 199);
        transition: 0.1s ease;
    }
}
.dropbtn:hover .dropbox-mobile {
    display: flex;
}
</style>