<template>
  <div class="btn">
    <img
      width="50"
      height="50"
      :src="assetsPath + require('@/assets/ui/sidebar_button.svg')"
      alt=""
    >
  </div>
</template>

<script>
export default {
name: 'SideBarButton',
data() {
    return {
        assetsPath: process.env.VUE_APP_ASSETS_PATH
    }
}
}
</script>

<style scoped>
</style>