<template>
  <div class="form-container">
    <form
      v-if="!serverResponse"
      @submit.prevent="checkForm"
    >
      <div class="input-container">
        <main-input
          v-model.trim="form.name"
          :type="'text'"
          :class="v$.form.name.$error ? 'is-invalid' : ''"
          class="input-item"
          :placeholder="'Ваше имя'"
        />
        <line-invalid v-if="v$.form.name.$error">
          Обязательное поле
        </line-invalid>
        <main-input
          v-model.trim="form.phone"
          :type="'text'"
          :class="v$.form.phone.$error ? 'is-invalid' : ''"
          class="input-item"
          :placeholder="'Номер телефона'"
        />
        <line-invalid v-if="v$.form.phone.$error && v$.form.phone.required.$invalid">
          Обязательное поле
        </line-invalid>
        <main-input
          v-model.trim="form.email"
          :type="'text'"
          :class="v$.form.email.$error ? 'is-invalid' : ''"
          class="input-item"
          :placeholder="'Email*'"
        />
        <line-invalid v-if="v$.form.name.$dirty && v$.form.email.email.$invalid">
          Некорректный e-mail
        </line-invalid>
      </div>
      <textarea
        v-model="form.comment"
        class="comment"
        type="text"
        rows="4"
        placeholder="Комментарий*"
      />
      <p class="p-optional">
        *необязательно
      </p>
      <div
        class="link"
        @click="$emit('closeModal')"
      >
        <router-link :to="'/conf'">
          Согласие на обработку персональных данных
        </router-link>
      </div>
      <input
        class="submit"
        type="submit"
        value="ОТПРАВИТЬ"
        @click="submit()"
      >
    </form>
    <div
      v-else
      class="form-sended"
    >
      {{ serverResponse }}
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email} from '@vuelidate/validators';
import axios from 'axios';
export default {
name: "FormVue",
setup() {
    return { v$: useVuelidate() }
},
data() {
    return {
        form: {
            name: '',
            phone: '',
            email: '',
            comment: ''
        },
        serverResponse: ''
    }
},
unmounted() {
    this.$store.commit('CHANGE_POSTBODY_PRODUCT', {})
},
validations: {
    form: {
        name: {required},
        phone: {required},
        email: {email}
    }
},
methods: {
    checkForm() {
        this.v$.form.$touch();

        
    },
    createUser() {
        this.$store.commit('CHANGE_POSTBODY_USER', this.form);
    },
    async submit() {
        const result = await this.v$.$validate();
        if (result) {
            this.createUser();
            this.postPost();
        }
    },
    async postPost() {
        let data = JSON.stringify(this.$store._state.data.postBody);
        this.serverResponse = 'Идёт отправка формы...';
        
        await axios.post('http://astrei-spb.ru/api-form.php', data) //changed!!!
            .then((res) => {
                if (res.status === 200) {
                    this.serverResponse = 'Заявка отправлена. Ожидайте ответа.'
                } else {
                    this.serverResponse = `Что-то пошло не так... Ошибка ${res.status}`
                }
            })
            .catch((err) => {
                let errText = !err.response ? 'Соединение с сервером отсутствует.' : err.response.status;
                this.serverResponse = `Что-то пошло не так... Ошибка: ${errText}`;
                
            });
    }
}
}
</script>

<style scoped>
.link {
    text-align: center;
    text-decoration: underline;
}
.p-optional {
    font-size: 12px;
}
.form-container {
    display: flex;
    justify-content: center;
    width: 50vw;
}
.input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.input-item {
    margin: 5px;
}
form {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 10px;
}
input, textarea {
    border-radius: 25px;
    padding: 5px 20px;
    border: 1px solid #000;
    margin: 5px;
    resize: none;
}
.submit {
    cursor: pointer;
    font-size: 30px;
    padding: 20px 0px;
    background: #264ac7;
    color: white;
    border: 0px;
}
.is-invalid {
    border-color: red
}

@media screen and (max-width: 600px) {
    .submit {
        font-size: 20px;
    }
    .form-container {
        padding: 2.3vw;
        width: 80vw;
    }
    form {
        width: 100%;
    }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
    .submit {
        font-size: 25px;
    }
}
@media screen and (min-width: 600px) {
    .form-container {
        padding: 30px;
    }
    .form {
        width: 90%;
    }
}
@media screen and (min-width: 601px) and (max-width: 1200px) {
    .form-container {
        width: 70vw;
    }
}
@media screen and (min-width: 1200px) {
    .form-container {
        width: 50vw;
    }
}
</style>