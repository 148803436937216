<template>
  <input
    :type="type"
    :placeholder="placeholder"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  >
</template>

<script>
export default {
name: 'MainInput',
props: {
    type: {
        type: String
    },
    placeholder: {
        type: String
    },
    modelValue: {
        type: Number
    }
},
emits: [
    'update:modelValue'
]
}
</script>

<style scoped>
input {
    border-radius: 25px;
    padding: 5px 20px;
    border: 1px solid #000;
}
</style>