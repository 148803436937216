<template>
  <p class="valid-control">
    <slot />
  </p>
</template>

<script>
export default {
name: 'LineInvalid'
}
</script>

<style scoped>
.valid-control {
    font-size: 14px;
    color: red;
    padding-left: 20px;
}
</style>