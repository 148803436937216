<template>
  <main-modal @close="$emit('close')">
    <cards-vue
      :type="'services'"
      :cards="$store.state.modal.items"
    />
  </main-modal>
</template>

<script>
export default {
    name: 'HomeModal',
    props: {
        cards: {
            type: Array
        }
    }
}
</script>

<style scoped>

</style>