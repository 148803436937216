<template>
  <div
    ref="modalMask"
    class="modal-mask"
    :style="styleMask"
    @click="closeModal"
  >
    <div
      ref="modalContainer"
      class="modal-container"
      :style="styleContainer"
      :class="animation"
    >
      <close-button class="close-btn" />
      <div
        class="modal-content"
        @click.stop=""
      >
        <div class="modal-wrapper">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'MainModal',
    data() {
        return {
            elHeight: 0,
            winHeight: 0,
            modalHeight: 0,
            styleContainer: '',
            styleMask: '',
            animation: ''
        }
    },
    mounted() {
        document.body.style.overflow = "hidden";
        this.placeModal();
    },
    updated() {
        this.placeModal();
    },
    unmounted() {
        document.body.style.overflow = "auto";
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        placeModal() {
            this.elHeight = this.$refs.modalContainer.clientHeight;
            this.winHeight = this.$refs.modalMask.clientHeight;
            
            if (this.elHeight / 2 > (this.winHeight / 2) - 170) {
                this.styleContainer = `top: 130px`;
                this.styleMask = "align-items: start";
            } else {
                this.styleContainer = "";
                this.styleMask = "align-items: center";
            }
        }
    }
}
</script>

<style scoped>
.modal-container {
    position: relative;
    padding-bottom: 30px;
}
.close-btn {
    position: absolute;
    right: -5vw;
}
.modal-content {
    display: block;
    width: max-content;
    height: max-content;
    background: #FFFFFF;
    border-radius: 29px;
}
.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
}
.modal-wrapper {
    display: flex;
    padding: 1.2vw 2.2vw;
}
@media screen and (max-width: 1000px) {
    .close-btn {
        top: -20px;
        right: -10px;
    }
}
</style>