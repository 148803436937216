<template>
  <offer-vue
    :title="'Козырьки над входами'"
    :imgsrc="'offer_imgs/peak/peak.webp'"
  >
    Изготовим и установим козырьки и навесы любого типа – арочные, двускатные, односкатные и т.д.,<br><br> 
    с покрытием из профнастила, поликарбоната, металлочерепицы, стального листа<br><br>
    <ul><li>В стоимость входит изготовление, окраска доставка и монтаж</li></ul>
  </offer-vue>
  <cards-vue 
    :type="'products'"
    :cards="cardsJson"
    @openModal="(product) => openModal(product)"
  />
  <div class="paragraph">
    <paragraph-vue>
      Представлены козырьки стандартных размеров, оптимальных для дверного проема шириной до 1200 мм.
      <br>Ширина – 1400 мм;
      <br>Высота (включая стойки) – 1000 мм;
      <br>Глубина – 900 мм.
      <br>В каждом конкретном случае возможна гибкая корректировка размеров, материалов и самой конструкции, а также способов окраски и крепления козырька.
    </paragraph-vue>    
  </div>
  <transition name="modal">
    <product-modal
      v-if="isModalVisible"
      :content="modalContent"
      :page-section="'Козырьки над входами'"
      @openForm="openForm()"
      @close="isModalVisible = false"
    />
  </transition>
  <transition name="modal">
    <main-modal
      v-if="isFormVisible"
      @close="isFormVisible = false"
    >
      <form-vue />
    </main-modal>
  </transition>
</template>

<script>
import cardsJson from './peak-cards.json'
export default {
name: 'PeakPage',
data() {
    return {
       isModalVisible: false,
       modalContent: new Object,
       isFormVisible: false,
       cardsJson: cardsJson
    }
},
methods: {
    openModal(product) {
        this.isModalVisible = !this.isModalVisible;
        this.modalContent = product;
    },
    openForm() {
        this.isFormVisible = true;
        this.isModalVisible = false;
    }
}
}
</script>

<style>
.paragraph {
    margin: 30px 0;
}
</style>