<template>
  <offer-vue
    :title="'Решетки на тех. этажах'"
    :imgsrc="'offer_imgs/grids_floor/grids_floor.webp'"
  >
    Для ограничения доступа посторонних лиц на чердаки, в подвалы и иные подсобные помещения многоквартирных домов предлагаем монтаж металлических перегородок
    <br><br>
    • Металлическая решетка-перегородка 
    для тех. этажа/выхода на крышу: <b>46 800 руб.</b> *<br><br>
    <ul><li>В стоимость входит изготовление, окраска доставка и монтаж</li></ul><br>
    <main-button
      @click="isFormVisible = true"
      @close="isFormVisible = false"
    >
      ЗАКАЗАТЬ
    </main-button>
  </offer-vue>
  <div class="description">
    <paragraph-vue>
      <b>Конструкция:</b><br>
      Перегородка состоит из двух сегментов: <br>треугольного, который закрывает просвет между лестничными маршами в горизонтальной плоскости и прямоугольного, в котором устанавливается дверь с врезным замком с защитной пластиной из стального листа, проушинами под навесной замок.<br><br>
      <b>Материалы:</b><br>
      Стальной уголок 40х40 мм, профильная стальная труба 15х15 мм. <br>Окраска эмалью по металлу.<br><br>
        
      *стоимость рассчитана исходя из стандартных (типовых) размеров лестницы: высота до 2800 мм, ширина до 1200 мм. <br>
    </paragraph-vue>
  </div>
  <transition name="modal">
    <main-modal
      v-if="isFormVisible"
      @close="isFormVisible = false"
    >
      <form-vue />
    </main-modal>
  </transition>
</template>

<script>
export default {
name: 'GridFloorPage',
data() {
    return {
        isFormVisible: false
    }
}
}
</script>